import React, { Component } from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";

export default function Layout({ logOut, name, children }) {
  return (
    <div>
      <NavMenu logOut={logOut} />
      <Container>{children}</Container>
    </div>
  );
}
