import { Divider, Grid, Typography } from "@material-ui/core";
import {
  Print as PrintIcon,
  TabOutlined,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fonts from "./Janna.ttf";
import "./Table.css";
import { CSVLink } from "react-csv";
function InputTableEdit({
  id = "",
  dataSource,
  colAttributes,
  onDelete,
  onEdit,
  canEdit,
  canDelete,
  pageSize = 10,
  handleRowDoubleClick,
  setData,
  title = "",
  getRowStyle = {},
  EDIT,
  ADD,
  DELETE,
  onRefresh,
}) {
  const { t, i18n } = useTranslation();
  const handleEdit = (row) => {
    if (onEdit) {
      onEdit(row);
    }
  };

  const handleDelete = (row) => {
    if (onDelete) {
      onDelete(row);
    }
  };

  const displayValue = (col, row) => {
    try {
      return col.data != null
        ? col.data?.find((e) => e[col.value] == row[col.field])[
            i18n.language == "en" && col.displayEn ? col.displayEn : col.display
          ]
        : row[col.field];
    } catch {
      return "";
    }
  };
  const [cols, setCols] = useState([]);
  useEffect(() => {
    setCols(applyColumns());
  }, [colAttributes, i18n.language, dataSource]);
  const applyColumns = () => {
    const res = [];

    res.push(
      ...colAttributes.map((column) => ({
        title: i18n.language == "ar" ? column.caption : column.captionEn,
        field: column.field.toString(),
        type: column.type,
        cellStyle: {
          textAlign: "center",
          minWidth: "150px",
          width: "150px",
        },
        headerStyle: {
          textAlign: "center",
          minWidth: "150px",
          width: "150px",
        },
        lookup: column.data ? convertArrayToObject(column.data, column) : null,
        render: column.customizeText
          ? (row, index) => (
              <column.customizeText
                value={displayValue(column, row)}
                data={row}
                index={index}
                column={column}
              />
            )
          : null,
      }))
    );

    return res;
  };
  function convertArrayToObject(array, col) {
    const res = array.reduce((obj, item) => {
      obj[item[col.value]] = item[col.display];
      return obj;
    }, {});
    return res;
  }

  const actions = () => {
    var res = [];
    if (canDelete) {
      res.push({
        icon: "delete",
        tooltip: "delete",
        onClick: (row, rowData) => {
          handleDelete(rowData);
        },
      });
    }
    if (canEdit) {
      res.push({
        icon: "edit",
        tooltip: "Edit",
        onClick: (row, rowData) => {
          handleEdit(rowData);
        },
      });
    }
    return res;
  };
  const editable = () => {
    console.log("edit");
    return {
      onRowAdd: (newRow, test) => {
        new Promise((resolve, reject) => {
          ADD(newRow)
            .then((res) => {
              console.log(newRow, test);
              //const updatedRows = [...dataSource, { ...res }];
              //setData(updatedRows);
              resolve();
            })
            .catch(() => {
              reject();
            });
          // setTimeout(() => {
          //   setData(updatedRows);
          //   resolve();
          // }, 2000);
        });
      },
      onRowDelete: (selectedRow) =>
        new Promise((resolve, reject) => {
          const index = selectedRow.tableData.id;
          const updatedRows = [...dataSource];
          console.log(selectedRow);
          updatedRows.splice(index, 1);
          DELETE(selectedRow[id])
            .then(() => {
              //       setData(updatedRows);
              resolve();
            })
            .catch(() => {
              reject();
            });
          // setTimeout(() => {
          //   setData(updatedRows);
          //   resolve();
          // }, 2000);
        }),
      onRowUpdate: (updatedRow, oldRow) =>
        new Promise((resolve, reject) => {
          const index = oldRow.tableData.id;
          //   const updatedRows = [...dataSource];
          //  updatedRows[index] = updatedRow;
          //  console.log(updatedRow);
          EDIT(updatedRow)
            .then(() => {
              //   setData(updatedRows);
              resolve();
            })
            .catch(() => {
              reject();
            });
          // setTimeout(() => {
          //   setData(updatedRows);
          //   resolve();
          // }, 2000);
        }),
    };
  };
  function centerText(doc, text, textY = 20) {
    const pageWidth = doc.internal.pageSize.getWidth();

    const fontSize = 20;
    const lineHeight = fontSize * 1.2;
    const textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    const textX = (pageWidth - textWidth) / 2;
    doc.setFontSize(fontSize);
    doc.text(text, textX, textY);
  }
  function printDocument(data) {
    const doc = new jsPDF("l", "mm", "a4");
    doc.addFont(fonts, "Arimo", "normal");
    doc.setFont("Arimo", "normal");
    autoTable(doc, {
      head: [colAttributes.map((col) => col.caption)],
      body: data.map((row) => {
        return colAttributes.map((column) => row[column["field"]]);
      }),
      headStyles: { font: "Arimo", fontStyle: "normal", align: "right" },
      styles: { font: "Arimo", fontStyle: "normal", align: "right" },
      startY: 40,
    });

    doc.save(title + ".pdf");
  }
  const Toolbar = (props) => {
    return (
      <div>
        <MTableToolbar {...props} />
        <Grid align="right" style={{ padding: 15 }}>
          <Typography variant="subtitle2">
            {/* Number of rows : {props.data.length} */}
            <IconButton onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
            <IconButton onClick={() => printDocument(props.data)}>
              <PrintIcon />
            </IconButton>
            <IconButton>
              <CSVLink
                headers={colAttributes.map((e) => ({
                  label: e.caption,
                  key: e.field,
                }))}
                data={props.data}
                style={{
                  fontSize: 13,
                }}
                filename={title + ".csv"}
              >
                <TabOutlined />
              </CSVLink>
            </IconButton>
          </Typography>
        </Grid>
        <Divider />
      </div>
    );
  };
  const createData = async (newData) => {
    // Make an API request to create a new record
    const result = await ADD(newData);
    return result;
  };
  const updateData = async (updatedData) => {
    // Make an API request to update the record
    const result = await EDIT(updatedData);
    return result;
  };
  const deleteData = async (deletedData) => {
    const result = await DELETE(deletedData[id]);
    return result == 200;
  };
  return (
    <>
      <>
        <MaterialTable
          data={dataSource}
          columns={cols}
          title={title}
          editable={{
            onRowAdd: createData,
            onRowUpdate: updateData,
            onRowDelete: deleteData,
          }}
          // editable={editable()}
          components={{ Toolbar }}
          options={{
            pageSize: pageSize && pageSize,
            pageSizeOptions: [10, 20, 50], // Set the available page size options
            columnsButton: true,
            filtering: true,
            grouping: true,
            thirdSortClick: false,
            headerStyle: {
              position: "sticky",
              top: "0",
            },
            rowStyle: getRowStyle,
          }}
          onRowClick={(event, row) =>
            handleRowDoubleClick != null && handleRowDoubleClick(row)
          }
          actions={actions()}
        />
      </>
      ;
    </>
  );
}

export default React.memo(InputTableEdit);
