import { Home } from "./view/home/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
];

export default AppRoutes;
