import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
export default function SubmitButton({
  loading,
  title,
  type = "button",
  onClick,
}) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Button
        color="primary"
        onClick={onClick}
        disabled={loading}
        type={type}
        style={{ width: "100%" }}
      >
        {title}
      </Button>
      {/* <Button
      <LoadingButton
        type={type}
        color="primary"
        loading={loading}
        variant="contained"
        onClick={onClick}
        fullWidth
      >
        {t(title)}
      </LoadingButton> */}
    </>
  );
}
