import { Input, Label } from "reactstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const TextInput = React.memo(
  ({ label, name, value, handleChange, disabled, type = "Text", error }) => {
    const { t, i18n } = useTranslation();
    return (
      <>
        <Label>{label}</Label>
        <Input
          fullWidth
          size="small"
          type={type}
          name={name}
          label={t(label)}
          variant="outlined"
          value={value}
          onChange={(e) => {
            console.log({ name: e.target.name, value: e.target.value });
            handleChange({ name: e.target.name, value: e.target.value });
          }}
          helperText={error != null ? error : null}
          error={Boolean(error != null)}
          sx={{ mb: 3 }}
        />
      </>
    );
  }
);

export default TextInput;
// import { TextField } from "@mui/material";
// import React from "react";
// import { useTranslation } from "react-i18next";

// const TextInput = React.memo(
//   ({ label, name, value, handleChange, disabled, type = "Text", error }) => {
//     const { t, i18n } = useTranslation();
//     return (
//       <TextField
//         fullWidth
//         size="small"
//         type={type}
//         name={name}
//         label={t(label)}
//         variant="outlined"
//         value={value}
//         onChange={(e) => {
//           console.log({ name: e.target.name, value: e.target.value });
//           handleChange({ name: e.target.name, value: e.target.value });
//         }}
//         helperText={error != null ? error : null}
//         error={Boolean(error != null)}
//         sx={{ mb: 3 }}
//       />
//     );
//   }
// );

// export default TextInput;
