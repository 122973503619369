import React, { useCallback, useState } from "react";
import TextInput from "../../../components/common/Inputs/TextInput";
import AlertMessage from "../../../components/common/alert/AlertMessage";
import SubmitButton from "../../../components/common/button/SubmitButton";
import { SEND_EMAIL } from "../Home.Api";

export default function SendEmail({ data }) {
  const [values, setValues] = useState({
    Email: "",
    Password: "",
    Host: "",
    Subject: "",
    Body: "",
    Port: 0,
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({
    State: false,
    Type: "error",
    Message: "",
  });

  const handleChange = useCallback(({ name, value }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);
  const onClick = useCallback(() => {
    console.log(values);
    if (!values.Email || !values.Password || !values.Body) {
      setOpen((prev) => ({
        ...prev,
        State: true,
        Type: "error",
        Message: "fill the inputs",
      }));
      return;
    }
    setLoading(true);
    SEND_EMAIL({ ...values, Data: data.map((e) => e.Id) })
      .then(() => {
        setOpen((prev) => ({
          ...prev,
          State: true,
          Type: "success",
          Message: "Email send successfully",
        }));
      })
      .catch(() => {
        setOpen((prev) => ({
          ...prev,
          State: true,
          Type: "error",
          Message: "error in connection try again",
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [values, data]);
  return (
    <div>
      <AlertMessage open={open} setOpen={setOpen} />
      <div className="row" style={{ margin: 0 }}>
        <div className="col-6">
          <TextInput
            label={"Email"}
            type={"email"}
            name="Email"
            value={values.Email}
            handleChange={handleChange}
          />
        </div>
        <div className="col-6">
          <TextInput
            label={"Password"}
            type={"password"}
            name="Password"
            value={values.Password}
            handleChange={handleChange}
          />
        </div>
        <div className="col">
          <TextInput
            label={"Port"}
            type={"number"}
            name="Port"
            value={values.Port}
            handleChange={handleChange}
          />
        </div>
        <div className="col">
          <TextInput
            label={"Host"}
            name="Host"
            value={values.Host}
            handleChange={handleChange}
          />
        </div>

        <div className="col-4">
          <TextInput
            label={"Subject"}
            name="Subject"
            value={values.Subject}
            handleChange={handleChange}
          />
        </div>
        <div className="col-9">
          <TextInput
            label={"Body"}
            name="Body"
            value={values.Body}
            handleChange={handleChange}
          />
        </div>
        <div className="col" style={{ alignSelf: "flex-end" }}>
          <SubmitButton loading={loading} title="Send" onClick={onClick} />
        </div>
      </div>
    </div>
  );
}
