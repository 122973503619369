import REQUEST from "../../services/Request";
export const GET_CLIENTS = async (data) => {
  return await REQUEST({
    method: "POST",
    url: "Clients/filter",
    data,
  });
};
export const EDIT_CLIENTS = async (data) => {
  return await REQUEST({
    method: "POST",
    url: data.Id > 0 ? "Clients/update" : "Clients",
    data,
  });
};

export const DELETE_CLIENTS = async (id) => {
  return await REQUEST({
    method: "Post",
    url: "Clients/delete/" + id,
  });
};
export const SEND_EMAIL = async (data) => {
  return await REQUEST({
    method: "POST",
    url: "send/email",
    data,
  });
};
