import React, { Component, useEffect, useState } from "react";
import InputTableEdit from "../../components/masterTable/InputTableEdit";
import { DELETE_CLIENTS, EDIT_CLIENTS, GET_CLIENTS } from "./Home.Api";
import SendEmail from "./components/SendEmail";
import { useCallback } from "react";
import Filter from "./components/Filter";

export function Home() {
  const [data, setData] = useState([]);
  const [values, setValues] = useState({
    Id: 0,
    Name: "",
  });
  const [loading, setLoading] = useState(false);
  // const getData = () =>
  //   GET_CLIENTS(values).then((res) => {
  //     setData(res);
  //   });
  // useEffect(() => {
  //  // getData();
  // }, []);
  const cols = [
    {
      caption: "Name",
      field: "Name",
      captionEn: "Name",
    },
    {
      caption: "Job",
      field: "Job",
      captionEn: "Job",
    },
    {
      caption: "Email",
      field: "Email",
      captionEn: "Email",
    },
    {
      caption: "Phone Number",
      field: "PhoneNumber",
      captionEn: "Phone Number",
    },
    {
      caption: "Note",
      field: "Note",
      captionEn: "Note",
    },
  ];
  const onEdit = () => {};
  const onDelete = () => {};
  const handleChange = useCallback(({ name, value }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);
  const fetchData = async (query) => {
    const { page, pageSize } = query;
    console.log(query);
    const filterObj = {
      Id: 0,
      Name: null,
    };
    if (query.filters.length > 0) {
      query.filters.forEach((e) => {
        filterObj[e.column.field] = e.value;
      });
    }
    console.log(filterObj);
    const result = await GET_CLIENTS({
      ...filterObj,
      Page: page,
      PageSize: pageSize,
    });

    return {
      data: result.Data,
      page: result.Page,
      totalCount: result.TotalCount,
    };
  };
  return (
    <div>
      <SendEmail data={data} />
      {/* <Filter
        loading={loading}
        values={values}
        getData={getData}
        handleChange={handleChange}
      /> */}
      <InputTableEdit
        id={"Id"}
        onDelete={onDelete}
        onEdit={onEdit}
        dataSource={fetchData}
        setData={setData}
        title="Clients"
        colAttributes={cols}
        EDIT={EDIT_CLIENTS}
        ADD={EDIT_CLIENTS}
        DELETE={DELETE_CLIENTS}
      />
    </div>
  );
}
