import React from "react";
import { Alert, Snackbar } from "@mui/material";
export default function AlertMessage({ open, setOpen }) {
  return (
    <Snackbar
      open={open.State}
      autoHideDuration={6000}
      onClose={() => setOpen({ ...open, State: false })}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity={open.Type}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {open?.Message}
      </Alert>
    </Snackbar>
  );
}
