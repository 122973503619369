import React, { Component, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./components/Layout";
import "./custom.css";
import "./App.css";
import Loadable from "./components/Loadable";

const JwtLogin = Loadable(lazy(() => import("./view/sessions/JwtLogin")));
export default function App() {
  useEffect(() => {
    const isValidToken = (accessToken) => {
      if (!accessToken) return false;
      //const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      console.log(currentTime);
      return new Date(accessToken.ExpiresOn) > currentTime;
    };
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    setState(accessToken && isValidToken(accessToken.user));
  }, []);
  const [state, setState] = useState(false);
  if (state) {
    return <User setState={setState} />;
  } else {
    return (
      <div style={{ height: "100vh" }}>
        <JwtLogin setState={setState} />
      </div>
    );
  }
}
function User({ setState }) {
  return (
    <Layout logOut={() => setState(false)}>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </Layout>
  );
}
